import React, {useState, useEffect} from 'react';
import hero from "../images/hero.png";
import hero2 from "../images/hero2.webp";
import hero3 from "../images/hero3.webp";
import hero4 from "../images/hero4.webp";
import hero5 from "../images/hero5.webp";
import hero6 from "../images/hero6.webp";
import hero7 from "../images/hero7.webp";
import hero8 from "../images/hero8.webp";
import hero9 from "../images/hero9.webp";
import "./slider.css";

export function Slider(props){
    const [images, setImages] = useState([hero, hero2, hero3, hero4, hero5, hero6, hero7, hero8, hero9])
    const [activeImg, setActive] = useState(0)

    useEffect(() => {
        const slider = setInterval(() => {
          setActive((activeImg + 1) % images.length);
        }, 5000);
        return function(){
          clearTimeout(slider);
        }
    }, [activeImg])

    return(
    <div className="bg-cover bg-center grid gap-64 justify-around items-center w-full sliding" style={{ backgroundImage : `url(${images[activeImg]})`, height : "110vh"}}>
      <div className="bg-black bg-opacity-40 text-white text-6xl sm:text-7xl mx-72 text-center py-5 px-3">
        Amazing Real Estate Photography<br/> Walkthrough Videos and <br/> 3D Virtual Tours
      </div>
    </div>
    )
}