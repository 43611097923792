import React from "react"
import {Link} from "gatsby"
import Layout from '../components/layout'
import {Slider} from "../components/slider"
import one from "../images/1.webp"
import berk from "../images/berkshirehathaway.webp"
import cent from "../images/Century_21_logo.webp"
import era from "../images/ERA-IPO.webp"
import firstteam from "../images/FirstTeam.webp"
import kw from "../images/keller-williams.webp"
import r1 from "../images/Realty-One.webp"
import photos from "../images/photosbox.webp"
import videos from "../images/video.webp"
import tour from "../images/360tour.webp"



const IndexPage = () => (
  <Layout title={"Knockout Photo"}>
    <Slider />

    <div className="w-full flex lg:flex-row lg:space-y-0 px-5 sm:px-10 flex-wrap justify-center lg:justify-evenly py-10 lg:px-0 bg-gray-200 flex-col space-y-10 ">

      <div className="lg:w-3/12 w-full bg-cover grid items-end justify-center"
        style={{backgroundImage : `url(${photos})`, height : "60vh", "minWidth" : "320px"}}
      >
        <Link to="/photos" className="bg-white p-3 border-solid border-black border uppercase text-3xl font-bold mb-5 hover:text-white cursor-pointer hover:bg-black">Photos</Link>
      </div>

      <div className="lg:w-3/12 w-full bg-cover bg-center grid items-end justify-center"
        style={{backgroundImage : `url(${videos})`, height : "60vh", "minWidth" : "320px"}}
      >
        <Link to="/video" className="bg-white p-3 border-solid border-black border uppercase text-3xl font-bold mb-5 hover:text-white cursor-pointer hover:bg-black">Video</Link>
      </div>

      <div className="lg:w-3/12 w-full bg-cover grid items-end justify-center"
        style={{backgroundImage : `url(${tour})`, height : "60vh", "minWidth" : "320px"}}
      >
        <Link to="/360tour" className="bg-white p-3 border-solid border-black border uppercase text-3xl font-bold mb-5 hover:text-white cursor-pointer hover:bg-black">3D Virtual Tour</Link>
      </div>

    </div>

    <div className="bg-gray-900 text-white w-full p-5 space-y-10 justify-evenly content-around " style={{minHeight : "600px"}}>
      <div className="flex md:flex-row flex-col justify-evenly">
        <div className="lg:w-6/12 w-full mb-5 lg:mb-0">
          <iframe className="w-full px-12" title="360tour" id="/tours/pBg6me7QvM" allow="vr;accelerometer;gyroscope;fullscreen" allowfullscreen frameborder="0" height="450" src="https://app.cloudpano.com/tours/pBg6me7QvM"></iframe>
        </div>
        <div className=" w-full lg:w-5/12 text-center space-y-10">
          <h3 className="text-7xl">SPECIAL OFFER</h3><br/>
          <p className="text-4xl mt-10 font-light">
          360 Virtual Tour special price only <br/>
          when you book at 4:30 pm. <br/><br/>
          <i>Up to 3000 sq. ft.</i>
          </p>
        </div>
      </div>

      <div className="flex flex-row justify-evenly">
        <Link external to="https://app.cloudpano.com/tours/pBg6me7QvM" className="bg-blue-500 text-white font-bold p-3 hover:bg-blue-300">View Sample</Link>
        <Link external to="https://knockoutphoto.hd.pics/view/default.asp?s=365600" className="bg-blue-500 text-white font-bold p-3 hover:bg-blue-300">View Prices Now</Link>
      </div>
    </div>

    <div className="pb-10 px-5">
      <h2 className="text-5xl font-bold text-center mt-10">Trusted Clients</h2>
      <div className="mt-20 grid grid-cols-3 grid-flow-row justify-items-center gap-y-12">
        <img src={one} alt="Coldwell Banker" className="w-48 lg:w-64 xl:w-80" />
        <img src={berk} alt="Berkshire Hathaway" className="w-48 lg:w-64 xl:w-80" />
        <img src={cent} alt="Century 21" className="w-48 lg:w-64 xl:w-80" />

        <img src={era} alt="ERA" className="w-48 lg:w-64 xl:w-80" />
        <img src={firstteam} alt="First Team" className="w-48 lg:w-64 xl:w-80" />
        <img src={kw} alt="Keller Williams" className="w-48 lg:w-64 xl:w-80" />

        <img src={r1} alt="Realty One" className="w-48 lg:-64 xl:w-80" />
      </div>
    </div>
  </Layout>
)

export default IndexPage
